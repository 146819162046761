<template>
  <div>
    <b-card>
      <b-row>
        <b-col />
        <b-col />
      </b-row>

      <div class="d-block text-center mt-3">
        <div class="main-div">
          <div class="d-flex justify-content-end">
            <vue-json-to-csv
              :json-data="tableRows"
              :labels="labelsForDownloadCsv"
              :csv-title="'Warehouse_csv_' + new Date().toISOString()"
              @success="() => handleCreatedCsvSuccess()"
              @error="val => handleDownloadCsvError(val)"
            >
              <b-button
                variant="primary"
                class="mr-2"
                :disabled="!tableRows.length"
              >
                {{ $t('downloadCsv') }}
              </b-button>
            </vue-json-to-csv>

            <b-button
              variant="primary"
              class="mb-3"
              @click="$refs.refInputEl.click()"
            >
              <input
                v-if="showInputFileTag"
                ref="refInputEl"
                type="file"
                class="d-none"
                accept=".xlsx,.xls,.csv"
                @input="handleCsvUploaded"
              />
              <span class="d-none d-sm-inline">
                {{ $t('importFile') }}
              </span>
            </b-button>
          </div>

          <b-row>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('productGroup') }}</label>
              <div>
                <treeselect
                  v-model="productGroupsSearch"
                  :multiple="true"
                  :options="productGroupsTreeData"
                  :no-children-text="'No categories'"
                  :placeholder="$t('productGroup')"
                  :max-height="100"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('productName') }}</label>
              <b-form-input
                id="productName"
                v-model="productNameSearch"
                name="productName"
                :placeholder="$t('productName')"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('tnVed') }}</label>
              <div>
                <treeselect
                  v-model="tnVedValues"
                  :multiple="true"
                  :options="tnVedTreeData"
                  :no-children-text="$t('noCategories')"
                  :placeholder="$t('productGroup')"
                  :limit="1"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('partNumber') }}</label>
              <b-form-input
                id="partNumber"
                v-model="partNumberSearch"
                name="partNumber"
                :placeholder="$t('partNumber')"
              />
            </b-col>
          </b-row>

          <!-- table -->
          <div class="table-container">
            <vue-good-table
              ref="vueGoodTableWarehouse"
              :columns="tableColumns"
              :rows="tableRows"
              :row-style-class="''"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectAllByPage: false,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true,
                selectAllByGroup: true,
              }"
              :sort-options="{
                enabled: true,
              }"
              :pagination-options="{
                enabled: true,
                perPage: tableParams.currentPerPage,
                mode: 'records',
              }"
              style-class="vgt-table condensed"
              :is-loading="isItemsLoading"
              @on-page-change="handlePageChange"
              @on-per-page-change="handlePageChange"
              @on-sort-change="handleSortChange"
              @on-selected-rows-change="handleSelectionChanged"
            >
              <div slot="emptystate">
                <span>
                  {{
                    productGroupsSearch.length || productNameSearch.length
                      || partNumberSearch.length || tnVedValues.length ?
                        $t('noProductsBySearch') : $t('noProducts')
                  }}
                </span>
              </div>
              <template
                slot="table-column"
                slot-scope="props"
              >
                <div v-if="props.column.field !== 'favorite'">
                  <span>
                    {{ props.column.label }}
                  </span>
                </div>
                <div v-else>
                  <span>
                    <b-icon-star
                      v-if="favoriteFilterState === 1"
                      font-scale="0.85"
                      class="active-filter"
                      @click="favoriteFilterClick(props.row)"
                    />
                    <b-icon-star-fill
                      v-else
                      font-scale="0.85"
                      :class="{'disable-favorite': favoriteFilterState === 0, 'active-filter': favoriteFilterState === 2}"
                      @click="favoriteFilterClick()"
                    />
                  </span>
                </div>
              </template>

              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'group_id'">
                  {{ $store.state.products.groups.find(group => group.id === props.row.group_id) ? $store.state.products.groups.find(group => group.id === props.row.group_id).name : 'Default' }}
                </span>
                <span v-else-if="props.column.field === 'supplier'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
                <div v-else-if="props.column.field === 'note'">
                  <div class="comments-div">
                    {{ props.row.note }}
                  </div>
                </div>
                <span v-else-if="props.column.field === 'delivery_type'">
                  {{ deliveryTypes[props.formattedRow[props.column.field].toLowerCase()] || props.formattedRow[props.column.field] }}
                </span>
                <div v-else-if="props.column.field === 'favorite'">
                  <div class="favorite-icon">
                    <b-icon-star-fill
                      v-if="props.row.favorite"
                      font-scale="0.85"
                      class="favorite-row-icon"
                      @click="favoriteClick(props.row)"
                    />
                    <b-icon-star
                      v-else
                      font-scale="0.85"
                      class="favorite-row-icon"
                      @click="favoriteClick(props.row)"
                    />
                  </div>
                </div>
                <span
                  v-else-if="props.column.field === 'actions'"
                  class=""
                >
                  <b-dropdown
                    variant="outline-primary"
                    size="sm"
                  >
                    <template #button-content>
                      <b-icon-plus-square
                        font-scale="0.95"
                        class="plus-icon"
                      />
                      <span class="mr-25 align-middle">
                        {{ $t('addToHotSale') }}
                      </span>
                    </template>

                    <b-dropdown-item
                      href="#"
                      @click="offerOfItemClick(null, props.row)"
                    >
                      <div>
                        <span style="font-weight: bold">
                          + {{ $t('createHotSale') }}
                        </span>
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-for="offer in offers"
                      :key="offer.id"
                      href="#"
                      @click="offerOfItemClick(offer.id, props.row)"
                    >
                      <div class="offer-content">
                        <div>
                          <span>#{{ offer.id }}: </span>
                        </div>
                        <div
                          v-for="(product, index) in offer.products"
                          :key="product.id"
                        >
                          <div class="product-name">
                            <span>{{ product.name }}</span>
                          </div>
                          <div class="product-count">
                            <span> ({{ product.count }})</span>
                            <span v-if="index !== offer.products.length - 1">
                              ,
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template
                slot="pagination-bottom"
              >
                <div />
              </template>
            </vue-good-table>
          </div>

          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex justify-content-start flex-wrap text-style">
              <div class="d-flex align-items-center">
                <div class="hot-sale-select-title">
                  <span>{{ $t('addSelectedProductsToHotSale') }}:</span>
                </div>
                <div class="hot-sale-select-div">
                  <b-form-select
                    v-model="hotSaleForProductsId"
                    class="ml-1 hot-sale-select"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="false"
                        disabled
                      >
                        <span>-- {{ $t('selectHotSale') }} --</span>
                      </b-form-select-option>
                    </template>

                    <b-form-select-option
                      :value="null"
                    >
                      <span>
                        <span>+ {{ $t('createHotSale') }}</span>
                      </span>
                    </b-form-select-option>

                    <b-form-select-option
                      v-for="offer in offers"
                      :key="offer.id"
                      :value="offer.id"
                    >
                      <div class="offer-content">
                        <div>
                          <span>#{{ offer.id }}: </span>
                        </div>
                        <div
                          v-for="(product, index) in offer.products"
                          :key="product.id"
                        >
                          <div class="product-name">
                            <span>{{ product.name }}</span>
                          </div>
                          <div class="product-count">
                            <span> ({{ product.count }})</span>
                            <span v-if="index !== offer.products.length - 1">
                              ,
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-form-select-option>
                  </b-form-select>
                </div>
                <div class="align-items-center">
                  <b-button
                    variant="primary"
                    :disabled="(hotSaleForProductsId !== null && !hotSaleForProductsId) || !selectedRows.length"
                    @click="addItemsToHotsaleClick()"
                  >
                    {{ $t('add') }}
                  </b-button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end flex-wrap text-style">
              <div class="d-flex align-items-center">
                <span class="d-flex">
                  {{ $t('itemsPerPage') }}:
                </span>
                <b-form-select
                  v-model="tableParams.currentPerPage"
                  :options="['10','25','50','100']"
                  class="mx-1 d-flex form-select"
                  @input="value => $refs.vueGoodTableWarehouse.perPageChanged({currentPerPage: value})"
                />
              </div>

              <div class="d-flex align-items-center ml-2">
                <span class="text-nowrap">
                  {{ tableParams.currentPerPage * (tableParams.currentPage - 1) + 1 }}
                  - {{ tableParams.currentPerPage * tableParams.currentPage >= tableParams.totalRows ? tableParams.totalRows : tableParams.currentPerPage * tableParams.currentPage }}
                  {{ $t('of') }} {{ tableParams.totalRows }} {{ $t('rows') }}
                </span>
              </div>

              <div class="d-flex align-items-center ml-3">
                <b-pagination
                  :value="1"
                  :total-rows="tableParams.totalRows"
                  :per-page="tableParams.currentPerPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1"
                  @input="value => $refs.vueGoodTableWarehouse.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div />
    </b-card>

    <import-csv
      v-if="modalVisible"
      :title="String($t('warehouse'))"
      :mode="'offer'"
      :import-mode="'itemsForWarehouse'"
      :excel-rows-full-received="excelRowsFull"
      @close-modal="closeImportFileModal"
      @update-products-all="updateProductsAll"
      @fetch-items="fetchItems"
    />

    <b-modal
      v-model="isShowTradingOffersEdit"
      size="xl"
      @hide="fetchOffers"
    >
      <trading-offers-new
        :is-new-mode-prop="!modalOfferId"
        :offer-id-prop="modalOfferId"
        :items-for-offer-prop="itemsForOfferProp"
        @close-offers-new="handleCloseOffersNew"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BPagination,
  BFormSelect,
  BFormSelectOption,
  BCard,
  BDropdownItem,
  BDropdown,
  BIconStarFill,
  BIconStar, BFormInput, BIconPlusSquare,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Ripple from 'vue-ripple-directive';
import i18n from '@/libs/i18n';
import readXlsxFile from 'read-excel-file';
import 'vue-good-table/dist/vue-good-table.css';
import ImportCsv from '@/views/modals/ImportCsv.vue';
// import the component
// eslint-disable-next-line import/no-extraneous-dependencies
import Treeselect from '@riophae/vue-treeselect';
// import the styles
// eslint-disable-next-line import/no-extraneous-dependencies
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import "vue-select/dist/vue-select.css";
import tnVedTreeData from '@/views/data/warehouseMockData';
import store from '@/store';
import TradingOffersNew from '@/views/trading/trading-offers/TradingOffersNew.vue';
import VueJsonToCsv from 'vue-json-to-csv';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BButton,
    BPagination,
    BFormSelect,
    BFormSelectOption,
    VueGoodTable,
    BCard,
    ImportCsv,
    BIconStarFill,
    BIconStar,
    BIconPlusSquare,
    Treeselect,
    BDropdownItem,
    BDropdown,
    TradingOffersNew,
    VueJsonToCsv,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: (() => []),
    },
    addBtnDisabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'offer',
    },
    excelRowsFullReceived: {
      type: Array,
      default: (() => []),
    },
  },
  data: () => ({
    modalVisible: false,
    showInputFileTag: true,
    selectedCategory: null,
    isImportProductsLoading: false,
    selected: [],
    selectObjects: {},
    selectedRows: [],
    // excelRowsFull: [],
    tableParams: {
      currentPage: 1,
      currentPerPage: 10,
      totalRows: 0,
      totalPages: 1,
      sortBy: 'name',
      type: 'asc',
    },
    isTableContainsHeaders: true,
    searchTerm: '',
    partNumberSearch: '',
    productGroupsSearch: [],
    productGroupsTreeData: [
      {
        id: 1,
        label: 'DEFAULT',
        children: [],
      },
    ],
    tnVedValues: [],
    productNameSearch: '',
    hotSaleForProductsId: false,
    tableRowsFull: [],
    excelRowsFull: [],
    isShowTradingOffersEdit: false,
    modalOfferId: null,
    itemsForOfferProp: [],
    isItemsLoading: false,
    favoriteFilterState: 0,
    filterParams: {},
    deliveryTypes: {
      retail: 'Retail',
      bulk: 'Bulk',
      oem: 'OEM',
    },
  }),
  computed: {
    offers() {
      return store.state.offers.ownOffers.filter(offer => offer.can_edit);
    },
    tableRows() {
      let rows = [...this.tableRowsFull];

      if (this.productGroupsSearch.length) {
        rows = rows.filter(row => this.productGroupsSearch.some(productGroupId => row.group_id === productGroupId));
      }

      if (this.tnVedValues.length) {
        rows = rows.filter(row => this.tnVedValues.some(tnVedValue => String(row.tnv).startsWith(tnVedValue)));
      }

      if (this.productNameSearch.length) {
        rows = rows.filter(row => row.name.toLocaleLowerCase().includes(this.productNameSearch.toLocaleLowerCase()));
      }

      if (this.partNumberSearch.length) {
        rows = rows.filter(row => String(row.index).includes(this.partNumberSearch)); // by part number
      }

      return rows;
    },
    labelsForDownloadCsv() {
      const resObj = {};

      this.tableColumns.filter(column => !['actions', 'favorite'].includes(column.field)).forEach(column => {
        resObj[column.field] = { title: column.label };
      });

      return resObj;
    },
    tnVedTreeData() {
      return tnVedTreeData;
    },
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    tableColumns() {
      const columns = this.mode === 'request' ? [
        {
          label: '',
          field: 'favorite',
          width: '30px',
          tdClass: 'favorite-td',
          sortable: false,
        },
        {
          field: 'group_id',
          label: i18n.t('productGroup'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'name',
          label: i18n.t('productName'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'index',
          label: i18n.t('partNumber'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'count',
          label: i18n.t('quantity'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'warranty',
          label: i18n.t('warranty'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'tnv',
          label: i18n.t('tnVed'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'delivery_type',
          label: i18n.t('deliveryType'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'note',
          label: i18n.t('comments'),
          width: '170px',
          sortable: true,
        },
      ] : [
        {
          label: '',
          field: 'favorite',
          width: '30px',
          tdClass: 'favorite-td',
          sortable: false,
        },
        {
          field: 'group_id',
          label: i18n.t('productGroup'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'name',
          label: i18n.t('productName'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'index',
          label: i18n.t('partNumber'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'tnv',
          label: i18n.t('tnVed'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'count',
          label: i18n.t('quantity'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'price',
          label: i18n.t('latestPrice'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'delivery_type',
          label: i18n.t('deliveryType'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'note',
          label: i18n.t('comments'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'actions',
          label: i18n.t('actions'),
          width: '180px',
          sortable: false,
        },
      ];

      if (this.isAdmin) {
        columns.splice(4, 0, {
          field: 'supplier',
          label: i18n.t('supplier'),
          width: '170px',
          sortable: true,
        });
      }

      return columns;
    },
  },
  async mounted() {
    if (this.$route.params.id && this.$route.params.id === 'new') {
      this.showProductEditing = true;
    }

    this.draggableProducts = this.products;

    this.$nextTick(() => {
      this.initSelectedRows();
    });

    await this.$store.dispatch('products/fetch'); // fetch groups

    await this.fetchItems();

    await this.fetchOffers();

    // await store.dispatch('users/fetch', {
    //   per_page: 100,
    //   page: 1,
    // });
    //
    // console.log('usersList');
    // console.log(this.$store.state.users.users);
  },
  methods: {
    offerOfItemClick(offerId, row) {
      this.modalOfferId = offerId;

      const index = this.tableRowsFull.findIndex(tableRow => tableRow.index === row.index);

      this.itemsForOfferProp = [this.tableRowsFull[index]];

      this.$nextTick(() => {
        this.isShowTradingOffersEdit = true;
      });
    },
    addItemsToHotsaleClick() {
      this.modalOfferId = this.hotSaleForProductsId;

      this.itemsForOfferProp = JSON.parse(JSON.stringify(this.selectedRows));

      this.$nextTick(() => {
        this.isShowTradingOffersEdit = true;
      });
    },
    initSelectedRows() {
      this.$nextTick(() => {
        this.selectedRows = this.$refs.vueGoodTableWarehouse.selectedRows;
      });
    },
    async fetchItems() {
      this.isItemsLoading = true;

      await this.$http.get('/v1/items', {
        params: {
          page: this.tableParams.currentPage,
          per_page: this.tableParams.currentPerPage,
          ...this.filterParams,
          // sort_by: this.tableParams.sortBy,
          // sort_type: this.tableParams.sortType,
        },
      }).then(({ data }) => {
        this.tableRowsFull = data.data.map(item => ({
          id: item.id,
          group_id: 1,
          name: item.name,
          index: item.index,
          tnv: (item.tnv_codes && item.tnv_codes[0]?.code) || null,
          note: item.description,
          count: item.stock?.count,
          price: item.last_price?.price,
          favorite: item.favorite,
          delivery_type: item.package_type,
          supplier: item.created_by?.email || '-',
        }));

        this.$nextTick(() => {
          this.tableParams.totalRows = data.meta.total;
          this.tableParams.totalPages = Math.round(this.tableParams.totalRows / this.tableParams.currentPerPage);
        });
      }).finally(() => {
        this.isItemsLoading = false;
      });
    },
    async fetchOffers() {
      await store.dispatch('offers/fetchOffers', {
        fetchParam: this.isAdmin ? 'all' : 'own',
        data: {
          per_page: 100,
          page: 1,
        },
      });
    },
    async handleCsvUploaded() {
      // here - uploadDocuments()

      const xlsxFile = this.$refs.refInputEl.files ? this.$refs.refInputEl.files[0] : null;

      this.excelRowsFull = await readXlsxFile(xlsxFile);

      this.modalVisible = true;
    },
    preparingRowsData(arrayOfArrays) {
      return arrayOfArrays.map(array => {
        const obj = {};

        array.forEach((elem, index) => {
          obj[index] = elem ? String(elem) : elem;
        });

        return obj;
      });
    },
    preparingColumnsData(columnHeaders, maxArrayLength = 0) {
      const array = Array.isArray(columnHeaders) ? columnHeaders : Object.values(columnHeaders);

      if (array.length < maxArrayLength) {
        array.push(...Array(maxArrayLength - array.length).fill('-'));
      }

      return array.map((elem, index) => ({
        label: elem || '-',
        field: `${index}`,
        width: '170px',
        thClass: '',
        sortable: true,
      }));
    },
    async handlePageChange(params) {
      this.tableParams.currentPage = params.currentPage;
      this.tableParams.currentPerPage = params.currentPerPage;

      await this.fetchItems();
    },
    handleSelectionChanged({ selectedRows }) {
      this.selectedRows = selectedRows;
    },
    async handleSortChange(params) {
      this.tableParams.sortBy = params[0].field;
      this.tableParams.sortType = params[0].type;

      await this.fetchItems();
    },
    close() {
      this.$emit('close-modal');
    },
    async favoriteClick(row) {
      const initValue = this.tableRows[row.originalIndex].favorite;
      const index = this.tableRowsFull.findIndex(tableRow => tableRow.index === row.index);

      this.tableRowsFull[index].favorite = !this.tableRowsFull[index].favorite;

      const { data } = await this.$http.post(`/v1/items/favorites/${row.id}`);

      if (!data.status) {
        this.tableRows[row.originalIndex].favorite = initValue;
      }
    },
    updateProductsAll(updatedProducts) {
      this.tableRowsFull = [...this.tableRowsFull, ...updatedProducts.map(product => ({ ...product, group_id: 1 }))];

      this.$refs.vueGoodTableWarehouse.pageChanged({ currentPage: this.tableParams.currentPage });
    },
    handleCreatedCsvSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('csvCreatedSuccessfully'),
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
    handleDownloadCsvError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('somethingWentWrong', { msg: err }),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    },
    handleCloseOffersNew() {
      this.isShowTradingOffersEdit = false;
    },
    async favoriteFilterClick() {
      if (!this.favoriteFilterState) {
        this.favoriteFilterState = 1;
        this.filterParams.favorite = 0;
      } else if (this.favoriteFilterState === 1) {
        this.favoriteFilterState = 2;
        this.filterParams.favorite = 1;
      } else {
        this.favoriteFilterState = 0;
        delete this.filterParams.favorite;
      }

      await this.fetchItems();
    },
    closeImportFileModal() {
      this.modalVisible = false;

      this.showInputFileTag = false;

      this.$nextTick(() => {
        this.showInputFileTag = true;
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
.favorite-td {
  height: auto;
  align-content: center;
}
</style>

<style lang="scss" scoped>
@import 'vue-good-table/dist/vue-good-table';

.divider {
  margin: 0;
}

.card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-class {
  background-color: #1ab7ea !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  padding-top: 50px !important;
}

.form-select {
  width: fit-content;
}

.text-style {
  color: #4e5154 !important;
}

.custom-class {
  background-color: #1ab7ea !important;
}

::v-deep .custom-th-class {
  background-color: #1ab7ea !important;
  text-align: center !important;
}

::v-deep .pointer {
  pointer-events: all !important;
  color: #1ab7ea !important;
}

::v-deep .custom-td-class {
  background-color: green !important;
}

::v-deep .vgt-checkbox-col {
  background-color: #1ab7ea !important;
}

::v-deep .column-select {
  position: absolute;
  top: -50px;
  width: 80%;
  z-index: 1000 !important;
}

.select-div {
  z-index: 1000 !important;
}

::v-deep .vgt-responsive {
  overflow-x: unset;
}

::v-deep .not-selected {
  color: #d0d0d0;
}

::v-deep .button-spinner {
  width: 14px;
  height: 14px;
}

.plus-icon {
  margin-right: 2px;
  padding-bottom: 2px;
}

.hot-sale-select-title {
  width: fit-content;
}

.hot-sale-select-div {
  width: 250px;
  margin-right: 40px;

  .hot-sale-select {
    width: 100%;
  }
}

.product-name {
  max-width: 200px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  align-content: center;
  margin-left: 5px;
}

.product-count {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  align-content: center;
}

.offer-content {
  max-width: 700px;
  text-overflow: ellipsis;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.disable-favorite {
  opacity: 0.3;
}

.active-filter {
  color: #7367F0;
  text-shadow: 0 0 1px #7367F0;
}

.favorite-row-icon {
  margin-left: 5px;
}

.favorite-icon {
  pointer-events: all;
  cursor: pointer;
  margin-bottom: 4px;
}

.comments-div {
  white-space: pre-line;
}
</style>
